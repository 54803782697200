<template>
    <div v-if="me" class="container">
        <StackRouterHeaderBar :title="'학력 인증하기'" :show-title="true" />
        <main class="wrapper">
            <div class="title m-b-8">어떻게 인증하시겠습니까?</div>
            <div class="required m-b-20">
                <div class="menu" v-for="menu in menus" :key="menu.id" @click="onClickMenu(menu.route)">
                    <span class="f-medium c-black f-16" v-html="menu.name" />
                    <span class="f-13 m-t-7 c-primary" v-html="menu.desc" />
                </div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    name: 'CertificateDetailPage',
    mounted() {},
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        menus() {
            return [
                {
                    id: 1,
                    name: '직접인증하기',
                    desc: '빠른 서류심사 후 서비스 이용가능',
                    route: 'CertificateCollegesPage',
                },
                {
                    id: 2,
                    name: '굿퍼슨에게 맡기기',
                    desc: '졸업증명서 대리 발급해드려요!',
                    route: 'ModalCertificate',
                },
            ]
        },
        declined() {
            return this.$store.getters.declined
        },
    },
    methods: {
        async onClickMenu(routeName) {
            const verificationBadge = this.$store.getters.verificationBadges.find(badge => badge.name === '신원 인증')
            const eng = /[a-zA-Z]+/

            if (routeName === 'ModalCertificate' && eng.test(this.me.profile.bachelor_u_name)) {
                await this.$modal.basic({
                    body: `죄송합니다. 해외대학교의 경우 대리 발급이 불가합니다.`,
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            } else if (routeName === 'CertificateCollegesPage') {
                this.$stackRouter.push({
                    name: routeName,
                })
            } else {
                this.$modal.custom({
                    component: 'ModalCertificate',
                    options: {
                        verificationBadge,
                        menuId: 2,
                        page: 1,
                    },
                })
            }
        },
        async save() {
            try {
                await this.$store.dispatch('loadMyVerificationBadges')
                this.$store.dispatch('loadMe')
                this.$store.dispatch('loadSettings')
            } catch (e) {
                console.log(e)
            }
        },
        back() {
            this.$stackRouter.pop()
        },
    },
    created() {
        this.$registerBackHandler(this.back)
    },
    beforeDestroy() {
        this.save()
        this.$unregisterBackHandler()
    },
}
</script>

<style lang="scss" scoped>
.bottom-border {
    border-bottom: 1px solid $grey-02;
}
.container {
    color: #111111;

    .wrapper {
        padding: 24px 16px;
        .title {
            @include spoqa-f-bold;
            font-size: 16px;
            line-height: 26px;
            padding: 30px;
            text-align: center;
        }
        .menu {
            display: flex;
            flex-direction: column;
            border: 1px solid $grey-04;
            padding: 25px;
            margin-bottom: 10px;
            border-radius: 8px;
            font-size: 16px;
            color: black;
        }
    }
}
</style>
